"use client";
import {
  UserOrganizationDelete,
  addUsersToOrganizationApiV1OrganizationsOrganizationIdUsersPut,
  batchRemoveUsersFromOrganizationApiV1OrganizationsOrganizationIdUsersRemovePut,
  createOrganizationApiV1OrganizationsPost,
  deleteOrganizationApiV1OrganizationsOrganizationIdDelete,
  getOrganizationApiV1OrganizationsOrganizationIdGet,
  listOrganizationUsersApiV1OrganizationsOrganizationIdUsersGet,
  listOrganizationsApiV1OrganizationsGet,
  removeUsersFromOrganizationApiV1OrganizationsOrganizationIdUsersMemberUserIdDelete,
  updateOrganizationApiV1OrganizationsOrganizationIdPut,
} from "@llamaindex/cloud/api";
import {
  QueryClient,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import { startTransition } from "react";

export const ORGANIZATION_KEY = "organizationInfo";
export const ORGANIZATIONS_KEY = "organizations";
export const ORGANIZATION_MEMBERS_KEY = "organizationMembers";

export function useOrganizationById({
  organizationId,
}: {
  organizationId: string;
}) {
  return useSuspenseQuery({
    queryKey: [ORGANIZATION_KEY, organizationId],
    queryFn: async () => {
      return getOrganizationApiV1OrganizationsOrganizationIdGet({
        organizationId,
      });
    },
  });
}

export async function refreshOrganizationById(
  queryClient: QueryClient,
  organizationId: string,
) {
  await queryClient.invalidateQueries({
    queryKey: [ORGANIZATION_KEY, organizationId],
  });
}

export async function refreshOrganizations(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [ORGANIZATIONS_KEY],
  });
}

export function useOrganizations() {
  return useSuspenseQuery({
    queryKey: [ORGANIZATIONS_KEY],
    queryFn: async () => listOrganizationsApiV1OrganizationsGet(),
  });
}

export function useNewOrganization() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      return createOrganizationApiV1OrganizationsPost({
        requestBody: {
          name,
        },
      });
    },
    onSuccess: async () => {
      await refreshOrganizations(queryClient);
    },
  });
}

export function useUpdateOrganization() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      name,
    }: {
      organizationId: string;
      name: string;
    }) => {
      return updateOrganizationApiV1OrganizationsOrganizationIdPut({
        organizationId,
        requestBody: {
          name,
        },
      });
    },
    onSuccess: async (_, { organizationId }) => {
      await refreshOrganizations(queryClient);
    },
  });
}

export function useDeleteOrganization() {
  const queryClient = useQueryClient();
  const { data: organizations } = useOrganizations();
  const { replace } = useRouter();
  return useMutation({
    mutationFn: async ({ organizationId }: { organizationId: string }) => {
      if (organizations.length === 1) {
        throw new Error("Cannot delete the last organization");
      }
      return deleteOrganizationApiV1OrganizationsOrganizationIdDelete({
        organizationId,
      });
    },
    onSuccess: async (_, { organizationId }) => {
      await refreshOrganizations(queryClient);
      const targetOrganizationId = organizations.find(
        (organization) => organization.id !== organizationId,
      )?.id;
      if (targetOrganizationId) {
        startTransition(() => {
          replace(`/organization/${targetOrganizationId}/dashboard`);
        });
      }
    },
  });
}

export function useOrganizationMembers({
  organizationId,
}: {
  organizationId: string;
}) {
  return useSuspenseQuery({
    queryKey: [ORGANIZATION_MEMBERS_KEY, organizationId],
    queryFn: async () => {
      return listOrganizationUsersApiV1OrganizationsOrganizationIdUsersGet({
        organizationId,
      });
    },
  });
}

export function useAddMember() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      email,
    }: {
      organizationId: string;
      email: string;
    }) => {
      return addUsersToOrganizationApiV1OrganizationsOrganizationIdUsersPut({
        organizationId,
        requestBody: [{ email }],
      });
    },
    onSuccess: async (_, { organizationId }) => {
      await queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_MEMBERS_KEY, organizationId],
      });
    },
  });
}

export function useDeleteMember() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      memberUserId,
    }: {
      organizationId: string;
      memberUserId: string;
    }) => {
      return removeUsersFromOrganizationApiV1OrganizationsOrganizationIdUsersMemberUserIdDelete(
        {
          organizationId,
          memberUserId,
        },
      );
    },
    onSuccess: async (_, { organizationId }) => {
      await queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_MEMBERS_KEY, organizationId],
      });
    },
  });
}

export function useDeleteMemberBatch() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      userOrganizationDeletes,
    }: {
      organizationId: string;
      userOrganizationDeletes: UserOrganizationDelete[];
    }) => {
      return batchRemoveUsersFromOrganizationApiV1OrganizationsOrganizationIdUsersRemovePut(
        {
          organizationId,
          requestBody: userOrganizationDeletes,
        },
      );
    },
    onSuccess: async (_, { organizationId }) => {
      await queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_MEMBERS_KEY, organizationId],
      });
    },
  });
}
